import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from './fallingWarning.css';
import hazard from '../../assets/images/hazardSign.svg';
import { getIsFalling } from '../../store/selectors/mining/mining.selectors';
import { Falling } from '../../definition/Enums';
import {isVertical} from "../../services/obstacles";

function FallingWarning() {
	const classes = useStyles({isVertical});
	const isFalling = useSelector(getIsFalling);
	if(isFalling !== Falling.Alert) return (<></>);
	return (
		<div className={classes.root}>
			<div className={classes.alert}>
				<div className={classes.title}>DANGER</div>
				<div className={classes.subTitle}>CLIFF EDGE</div>
				<img className={classes.stop} src={hazard}/>
			</div>
		</div>
	);
}

export default FallingWarning;
