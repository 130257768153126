import {makeStyles} from '@material-ui/core/styles';
import {getSeverityColors} from '../../definition/severity';

interface Props {
    size: number;
    severity: number;
}

export default makeStyles({
    root: props => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        top: '20%'
    }),
    label: (props: Props) => ({
        color: getSeverityColors(props.severity).icon,
        fontFamily: 'Mukta Mahee',
        fontSize: props.size * 0.071,
        lineHeight: 1,
    }),

    symbol: props => ({
        width: props.size * 0.35,
        objectFit: 'contain'
    }),
});
