import './App.scss';
import './overrides.css';
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { initSideEffects } from './core/services/sideEffects';
import "@fontsource/mukta-mahee";
import "@fontsource/mukta-mahee/500.css";
import "@fontsource/mukta-mahee/600.css";
import "@fontsource/rubik/variable.css";
import FullMapPage from './pages/fullMap/FullMapPage';
import { getBlockedSize, getCurrentPage } from './store/selectors/core.selector';
import MiniScreen from './pages/miniScreen/MiniScreen';
import { SnackbarProvider } from 'notistack';
import SnackBar from './features/snackBar/SnackBar';
import useStyles from './app.css';
import { changeSensorStatus, startSensorStatusListener } from './features/sensors/actions/sensors.actions';
import { onLogIn } from './core/auxilary/initielizers';
import FallingWarning from './features/fallingWarning/FallingWarning';
import BermPage from './pages/berm/BermPage';
import StatusAlert from "./features/mining-truck/StatusAlert/StatusAlert";
import { useKeycloak } from "@react-keycloak/web";
import { keycloakService } from "./services/keycloak";
import { config } from './services/config';
import { setUserInfo } from './store/actions/core.actions';
import NoSitesPage from './pages/noSites/NoSitesPage';
import { getSelectedSite } from './store/selectors/mining/mining.selectors';
import mineceptServerSocket from './core/services/minecept/mineceptServerSocket';
import Dashboard from './features/charts/Dashboard';
import AlertsSound from "./features/adas-alerts/sounds/AlertsSound";
const maxNumberOfSnackBars = parseInt(process.env.REACT_APP_MAX_SNAKBARS as string);
const snackBarDuration = parseInt(process.env.REACT_APP_SNAKBAR_DURATION as string) * 1000;

const pages = new Map([
	['fullMap', FullMapPage],
	['miniScreen', MiniScreen],
	['berm', BermPage],
	['dashboard', Dashboard]
]);

const App: React.FC = () => {
	const currentPage = useSelector(getCurrentPage);
	const shiftDistance = useSelector(getBlockedSize);
	const selectedSite = useSelector(getSelectedSite);
	const classes = useStyles({shiftDistance});
	const isShowStatusAlert = (isNoSites: boolean) => {
		if (isNoSites) {
			return false;
		}
		const envGuiType = process.env.REACT_APP_GUI_TYPE
		const guiType = envGuiType && envGuiType.trim();
		return guiType && guiType !== 'MANAGER';
	};
	const dispatch = useDispatch();
	const isLoggedIn = useRef(false);

	let isInitialized = true;
	let isAuthenticated = true;

	if (config.isRequiredLogin) {
		const { initialized, keycloak } = useKeycloak();
		if (!isLoggedIn.current && keycloakService.setKeycloak(initialized, keycloak)) {
			const userInfo = keycloakService.userInfo();
			if (userInfo) {
				dispatch(setUserInfo(userInfo));
				isLoggedIn.current = true;
				onLogIn();
			}
		}
		isInitialized = keycloakService.isInitialized();
		isAuthenticated = keycloakService.isAuthenticated();
		if (isInitialized && !isAuthenticated) {
			keycloakService.login();
		}
	} else if (!isLoggedIn.current) {
		isLoggedIn.current = true;
		onLogIn();
	}

	useEffect( ()=>{
		if (!isInitialized) {
			return;
		}
		initSideEffects();
	},[]);

	useEffect(()=>{
		if (!isInitialized) {
			return;
		}
		startSensorStatusListener(changeSensorStatus);
	})

	useEffect(() => {
		if (!selectedSite || !config.isMultiSite) {
			return;
		}
		mineceptServerSocket.initSocketListeners();
	}, [selectedSite])

	if (!isAuthenticated) {
		return (<></>);
	}

	let Page: React.FC = FullMapPage;
	const userInfo = keycloakService.isAuthenticated() && keycloakService.userInfo();
	const isNoSites = Boolean(config.isMultiSite && userInfo && userInfo.sites.length === 0);
	if (isNoSites) {
		Page = NoSitesPage;
	} else {
		Page = pages.get(currentPage) || FullMapPage;
	}

	return (
			<div className='app'>
				{isShowStatusAlert(isNoSites) && <StatusAlert/>}
				<SnackbarProvider
					anchorOrigin={{vertical: 'top', horizontal: 'left'}}
					maxSnack={maxNumberOfSnackBars}
					autoHideDuration={snackBarDuration}
					classes={{
						root: classes.snack
					}}
					content={(key, data) => (
						<SnackBar id={key} data={data} />
					)}
				>
					<FallingWarning />
					<Page />
					<AlertsSound />
				</SnackbarProvider>
			</div>
	);
};


export default App;
