import classes from './Options.module.css';
import {FormControl, MenuItem, Select} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Input from '@material-ui/core/Input';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchDashboardData,
} from '../../../store/actions/mining.actions';
import {
    DashboardPeriods,
    DASHBOARD_ALL_SITES_OPTION,
} from '../../../store/reducers/mining/IMiningReducerState';
import {
    getDashboardRange,
    getSites,
    getDashboardSite,
    getDashboardPeriod
} from '../../../store/selectors/mining/mining.selectors';
import {formatDateRange} from '../../../core/auxilary/formatting';

const NoSiteSelectedValue = 'Select Site';
const NoPeriodSelectedValue = 0;
const NoPeriodSelectedText = 'Select Period';

const Options = () => {
    const [site, setSites] = useState(useSelector(getDashboardSite));
    const [period, setPeriod] = useState(useSelector(getDashboardPeriod));
    const dispatch = useDispatch();
    const sites = useSelector(getSites);
    const dashboardRange = useSelector(getDashboardRange);
    useEffect(() => {
        dispatch(fetchDashboardData(site, period));
    }, []);

    const siteOptions = [DASHBOARD_ALL_SITES_OPTION, ...sites.map(x => x.id)];
    const periodOptions = Object.values(DashboardPeriods);
    const periodKeys = Object.keys(DashboardPeriods);

    const handleSiteChange = (event: React.ChangeEvent<{ value: any }>) => {
        let selectedOption: string =
            event.target.value && (event.target.value as string).trim();
        const noOptionPicked = selectedOption.length === 0;
        if (noOptionPicked) {
            setSites(NoSiteSelectedValue);
        } else {
            setSites(selectedOption);
            if (selectedOption !== NoSiteSelectedValue && period !== NoPeriodSelectedValue) {
                dispatch(fetchDashboardData(selectedOption, period));
            }
        }
    };

    const handlePeriodChange = (event: React.ChangeEvent<{ value: any }>) => {
        let selectedOption: number | undefined = event.target.value;
        if (selectedOption === undefined) {
            setPeriod(0);
        } else {
            setPeriod(selectedOption);
            if (selectedOption !== NoPeriodSelectedValue && site !== NoSiteSelectedValue) {
                dispatch(fetchDashboardData(site, selectedOption));
            }
        }
    };

    return (
        <div className={classes.options}>
            <FormControl
                className={`${
                    classes.formControl
                } filterbar-form-control-style-override`}
            >
                <Select
                    className={classes.select}
                    value={site}
                    input={<Input/>}
                    onChange={handleSiteChange}
                >
                    <option disabled hidden value={NoSiteSelectedValue}>
                        {NoSiteSelectedValue}
                    </option>
                    {siteOptions.map((key, index) => (
                        <MenuItem key={key} value={key}>
							<span className={classes.selectText}>
								{index === 0 ? key : sites[index - 1].name}
							</span>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl
                className={`${
                    classes.formControl
                } filterbar-form-control-style-override`}
            >
                <Select
                    className={classes.select}
                    value={period}
                    input={<Input/>}
                    onChange={handlePeriodChange}
                >
                    <option disabled hidden selected={undefined} value={NoPeriodSelectedValue}>
                        {NoPeriodSelectedText}
                    </option>
                    {periodOptions.map((value, index) => (
                        <MenuItem key={value} value={value}>
                            <span className={classes.selectText}>{periodKeys[index]}</span>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <label className={classes.datesRange}>{formatDateRange(dashboardRange, period === 1)}</label>
            </FormControl>
        </div>
    );
};
export default Options;
