import React from 'react';
import useStyles from './alertInWheel.css';
import {getHazardLabel} from '../../core/services/minecept/auxilary';
import {limitStringLength} from '../../core/auxilary/strings';
import {getHazardIcon} from "../../core/services/icons/obstacleIcons";

interface IProps {
    size: number
    severity: number
    type: number
    description?: string
}

const miniMapSize = parseInt(process.env.REACT_APP_MINI_MAP_SIZE as string);

function AlertInWheel({size, severity, type, description}: IProps) {
    const classes = useStyles({size, severity});
    const symbol = getHazardIcon(type, severity);
    const label = getHazardLabel(type, description);
    const small = size === miniMapSize;
    return (
        <div className={classes.root}>
            <img className={classes.symbol} src={symbol}/>
            <span className={classes.label}>{limitStringLength(label, small ? 17 : 20)}</span>
        </div>
    );
}

export default AlertInWheel;
