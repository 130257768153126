import {makeStyles} from '@material-ui/core/styles';
import {isVertical} from "../../services/obstacles";

interface Props {
    miniMapSize: number
}

export default makeStyles({
    root: {
        backgroundColor: '#3a475d',
        width: '100%',
        height: '100%',
    },
    // @ts-ignore
    miniMap: ({miniMapSize}: Props) => {
        const style = {
            position: 'fixed',
            left: `calc(50% - ${Math.floor(miniMapSize / 2)}px)`,
            bottom: '5%',
        };
        if (isVertical) {
            style.bottom = '20%';
        }
        return style;
    },
    logoContainer: {
        position: 'absolute',
        left: 27,
        bottom: 15,
    },
    logo: {
        width: 90,
    },
    clientLogo: {
        position: 'fixed',
        right: 23,
        bottom: 40,
        width: 90,
    },
    version: {
        color: '#99A4B9',
        fontSize: 12,
        textAlign: 'center',
    },
    alerts: (props: Props) => {
        const style = {
            width: 313,
            padding: 15,
        };
        if (isVertical) {
            style['margin'] = 'auto';
        } else {
            style['right'] = 0;
            style['position'] = 'absolute';
        }
        return style;
    },
    statusBar: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    // @ts-ignore
    muteButton: (props: Props) => {
        const style = {
            position: 'absolute',
            top: 24,
            // '&:focus, &:hover,&:active,&::before, &:active::before, &:focus::before, &.Mui-active, &.Mui-focusVisible': {
            // 	background: '#3a475d',
            // },
        };
        if (isVertical) {
            style['right'] = 40;
        } else {
            style['left'] = 40;
        }
        return style;
    },
});
