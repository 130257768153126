import Axios from 'axios';
import { AudioSound, AudioSoundEvent } from './audioSound';
import { EventEmitter } from './eventEmitter';
import { NoiseSound } from './noiseSound';

const NONE = 'none';

export enum AudioPlayerEvent {
	/** callback: (sound: string) => void */
	loading = 'loading',
	/** callback: (sound: string) => void */
	loaded = 'loaded',
	/** callback: (sound: string, error: any) => void */
	loadError = 'loadError',
	/** callback: (sound: string) => void */
	playing = 'playing',
	/** callback: (sound: string) => void */
	stopping = 'stopping',
	/** callback: (sound: string) => void */
	stopped = 'stopped',
	/** callback: (sound: string) => void */
	stopError = 'stopError',
	/** callback: (sound: string) => void */
	loopCompleted = 'loopCompleted',
	/** callback: (volume: number, isMute: boolean) => void */
	volumeChanged = 'volumeChanged',
	/** callback: (volume: number, isMute: boolean) => void */
	muteChanged = 'muteChanged',
	/** callback: () => void */
	backgroundOn = 'backgroundOn',
	/** callback: () => void */
	backgroundOff = 'backgroundOff',
}

export class AudioPlayer {
	private context: AudioContext;
	private masterGain: GainNode;
	private sounds: { [key: string]: AudioSound };
	private volume: number;
	private isMute: boolean;
	private noiseSound: NoiseSound;
	private eventEmitter: EventEmitter;

	constructor() {
		this.context = new AudioContext();
		this.masterGain = this.context.createGain();
		this.masterGain.gain.setValueAtTime(1, this.context.currentTime);
		this.masterGain.connect(this.context.destination);
		this.sounds = {};
		this.volume = 1;
		this.isMute = false;
		this.noiseSound = new NoiseSound(this.context, this.masterGain);
		this.eventEmitter = new EventEmitter(this);
	}

	async play(
		id: string,
		sound: string,
		isLoop?: boolean,
		rate?: number,
		offset?: number
	) {
		if (!(id in this.sounds)) {
			const isLoaded = await this.load(id, sound);
			if (!isLoaded) {
				return false;
			}
		}

		if (this.context.state !== 'running') {
			await this.context.resume();
		}

		this.sounds[id].play(isLoop, rate, offset);
		this.eventEmitter.emit(AudioPlayerEvent.playing, id);
		return true;
	}

	private soundCallback(event: AudioSoundEvent, id: string) {
		if (event === AudioSoundEvent.completed) {
			this.eventEmitter.emit(AudioPlayerEvent.stopped, id);
		} else if (event === AudioSoundEvent.looped) {
			this.eventEmitter.emit(AudioPlayerEvent.loopCompleted, id);
		}
	}

	async load(id: string, sound: string) {
		try {
			this.eventEmitter.emit(AudioPlayerEvent.loading, id);
			const { data } = await Axios.get(sound, {
				responseType: 'arraybuffer',
			});

			const decodedData = await this.context.decodeAudioData(data);
			this.sounds[id] = new AudioSound(
				id,
				this.context,
				this.masterGain,
				decodedData,
				this.soundCallback.bind(this)
			);
			this.eventEmitter.emit(AudioPlayerEvent.loaded, id);
			return true;
		} catch (err) {
			console.error(`Failed to load sound "${sound}", reason:`, err);
			this.eventEmitter.emit(AudioPlayerEvent.loadError, id, err);
			return false;
		}
	}

	stop(id: string) {
		if (!(id in this.sounds)) {
			return false;
		}
		this.eventEmitter.emit(AudioPlayerEvent.stopping, id);
		const isStopped = this.sounds[id].stop();
		const event = isStopped
			? AudioPlayerEvent.stopped
			: AudioPlayerEvent.stopError;
		this.eventEmitter.emit(event, id);
		return true;
	}

	getVolume() {
		return this.volume;
	}

	setVolume(volume: number) {
		if (this.volume == volume) {
			return false;
		}
		this.volume = volume;
		if (this.isMute) {
			this.eventEmitter.emit(
				AudioPlayerEvent.volumeChanged,
				true,
				volume
			);
			return false;
		}
		this.setVolumeInternal(volume, AudioPlayerEvent.volumeChanged);
		return true;
	}

	getIsMute() {
		return this.isMute;
	}

	mute() {
		if (this.isMute) {
			return false;
		}
		this.isMute = true;
		this.setVolumeInternal(0, AudioPlayerEvent.muteChanged);
		return true;
	}

	unmute() {
		if (!this.isMute) {
			return false;
		}
		this.isMute = false;
		this.setVolumeInternal(this.volume, AudioPlayerEvent.muteChanged);
		return true;
	}

	private setVolumeInternal(volume: number, event: AudioPlayerEvent) {
		this.masterGain.gain.setValueAtTime(volume, this.context.currentTime);
		this.eventEmitter.emit(event, this.volume, this.isMute);
	}

	getIsBackgroundAudioOn() {
		return this.noiseSound.getIsOn();
	}

	/**
	 * Start/stop background audio.
	 * Used to make sure the audio context keeps running behind the scenes
	 * @param setOn Start/stop background audio
	 * @param interval Play background audio every interval, undefined for continuous play
	 * @param duration Play background audio for limited time every interval, undefined for continuous play
	 */
	setBackgroundAudio(setOn: boolean, interval?: number, duration?: number) {
		if (setOn) {
			this.noiseSound.play(interval, duration);
			this.eventEmitter.emit(AudioPlayerEvent.backgroundOn);
		} else {
			this.noiseSound.stop();
			this.eventEmitter.emit(AudioPlayerEvent.backgroundOff);
		}
	}

	on(event: AudioPlayerEvent, callback: any, id?: any) {
		this.eventEmitter.on(event, callback, false, id);
	}

	once(event: AudioPlayerEvent, callback: any, id?: any) {
		this.eventEmitter.once(event, callback, id);
	}

	off(event: AudioPlayerEvent, callback?: any, id?: any) {
		this.eventEmitter.off(event, callback, id);
	}

	isPlaying(id?: string) {
		return Object.entries(this.sounds).some(
			([soundId, sound]) =>
				sound.getIsPlaying() && (!id || soundId === id)
		);
	}

	getDuration(id: string) {
		const sound = this.sounds[id];
		if ( !sound ) {
			return undefined;
		}
		return sound.getDuration();
	}

	isLoaded(id: string) {
		return id in this.sounds;
	}
}
export default new AudioPlayer();
