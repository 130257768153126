import { config } from './config';
import { SoundObject, PlayersMap } from '../interfaces/Sounds';
import { log } from './debug';
import AudioPlayer, { AudioPlayerEvent } from './audioPlayer/audioPlayer';

const NONE = 'none';

class SoundPlayer {
	private isReady: boolean;
	private currentSound: string | undefined;
	private nextSound: string | undefined;
	private isWaitingForSoundToComplete: boolean;
	private players: PlayersMap;

	public constructor() {
		this.players = {};
		this.isReady = false;
		this.currentSound = undefined;
		this.nextSound = undefined;
		this.isWaitingForSoundToComplete = false;
	}

	public async init() {
        try {
			if (config.general.sounds.isWaitForInteraction) {
				await this.waitForInteraction();
			}
            const isLoaded = await this.initSounds();
            if (!isLoaded) {
                console.error("Failed to load some sounds!");
                return;
            }
            log('players after init', Object.values(this.players));
			if (config.general.sounds.isPlayBackgroundSound) {
				this.playBackgroundSound();
			}
            this.isReady = true;
            log('Sound player after init');
        } catch (err) {
            console.error("Failed to initialize sound player:", err);
        }
	}

	public getIsReady() {
		return this.isReady;
	}

	public stopAll() {
		log('stopping all sounds');
		if (!this.isReady) {
			log('Received stop sounds but not ready yet');
			return;
		}
		Object.keys(this.players).forEach(playerKey => {
			AudioPlayer.stop(playerKey);
		});
	}

	public setMuteAndVolume(isMute: boolean, volume: number) {
		if (!this.isReady) {
			log('Received volume change but not ready yet', { isMute, volume });
			return;
		}
		if (isMute) {
			AudioPlayer.mute();
		} else {
			AudioPlayer.unmute();
		}
		AudioPlayer.setVolume(volume);
	}

	private async internalPlay(sound: string | undefined, offset:number=0) {
		this.currentSound = sound;
		this.nextSound = undefined;
		if (!this.isReady) {
			log(`Not ready! requested to play "${sound}"`);
			return;
		}
		if (!sound) {
			return;
		}
		log('Wanted to play:', sound);
		const playerObj = this.players[sound];
		if (!playerObj) {
			return;
		}
		const rate = playerObj.speed;
		const isPlay = await AudioPlayer.play(
			sound,
			playerObj.src,
			playerObj.loop,
			rate,
			offset
		);
		if (isPlay) {
			log(`Play status for "${sound}": ${isPlay}`);
		}
	}

	public play(newSound: string | undefined, offset?:number): boolean {
		log('Should play sound:', newSound);
		if (!this.currentSound) {
			log('Immediate play:', newSound);
			this.internalPlay(newSound,offset);
			return true;
		}
		if (this.isWaitingForSoundToComplete) {
			log('Queuing sound from/to:', this.nextSound, newSound);
			this.nextSound = newSound;
			return false;
		}
		const playerObj = this.players[this.currentSound];
		if (!playerObj || !AudioPlayer.isPlaying(this.currentSound)) {
			log(
				'Not playing, so immediate, previous/new sound:',
				this.currentSound,
				newSound
			);
			this.internalPlay(newSound,offset);
			return true;
		}
		const soundsToNotCut = config.soundList.soundsToNotCut;
		const canCutSound =
			soundsToNotCut.find(sound => sound === this.currentSound) ===
			undefined;
		if (canCutSound) {
			log('Can cut sound, current/new:', this.currentSound, newSound);
			this.stop();
			this.internalPlay(newSound,offset);
			return true;
		} else {
			log(
				'Wait for sound to complete, current/new:',
				this.currentSound,
				newSound
			);
			this.nextSound = newSound;
			this.isWaitingForSoundToComplete = true;
			AudioPlayer.once(
				AudioPlayerEvent.loopCompleted,
				this.playNextSound.bind(this)
			);
			return false;
		}
	}

	public stop() {
		if (!this.isReady) {
			log(`Not ready! requested to stop "${this.currentSound}"`);
			return;
		}
		if (!this.currentSound) {
			return;
		}
		log('Stopping sound:', this.currentSound);
		const playerObj = this.players[this.currentSound];
		if (!playerObj) {
			return;
		}
		AudioPlayer.stop(this.currentSound);
	}

	private async initSounds() {
        log("Initializing sounds");
		const soundListConfig = config.soundList.sounds;
		if (!soundListConfig) {
			return {};
		}
		const soundList: PlayersMap = Object.fromEntries(
			Object.entries(soundListConfig as {
				[index: string]: SoundObject;
			}).filter(([_, def]) => def.src !== NONE)
		);
		log("Config sounds:", soundList);
        const loadPromises = Object.entries(soundList)
            .map(([key, def]) => AudioPlayer.load(key, def.src));
        const loadResults = await Promise.all(loadPromises);
        if (!loadResults.every(x => x)) {
            const keys = Object.keys(soundList);
            const loadErrorSounds = loadResults
                .map((x, i) => x ? i : undefined)
                .filter(x => x !== undefined)
                .map(x => keys[x || 0]);
            console.error("Failed to load sounds:", loadErrorSounds);
            return false;
        }
        this.players = soundList;
        return true;
	}

	private playNextSound() {
		log(
			`Sound finished playing ("${this.currentSound}"), starting: "${
				this.nextSound
			}"`
		);
		this.isWaitingForSoundToComplete = false;
		this.stop();
		this.internalPlay(this.nextSound);
	}

	private async waitForInteraction() {
		log("Waiting for interaction");
		const sounds = Object.entries(config.soundList.sounds).filter(([_, sound]) => Boolean(sound.src));
		if (sounds.length === 0) {
			console.error("No sounds defined, cannot wait for interaction");
			return;
		}
		const soundSource = sounds[0][1].src;
		return new Promise((resolve) => {
			const tryPlay = async () => {
			  const audio = new Audio(soundSource);
			  audio.volume = 0;
	  
			  try {
				await audio.play();
				log("detected user interaction, initializing audio player");
				resolve(undefined);
			  } catch {
				log("Waiting for user interaction to initialize audio");
				setTimeout(tryPlay, config.general.sounds.waitForInteractionInterval);
			  }
			};
			tryPlay();
		  });
	}

	private playBackgroundSound() {
		const soundConfig = config.general.sounds;
		const isContinuous = soundConfig.isBackgroundSoundContinuous;
		const playInterval = soundConfig.backgroundSoundPlayInterval;
		const playDuration = soundConfig.backgroundSoundPlayDuration;
		log(`Playing background sound: continuous=${isContinuous}, ` + 
			`interval=${playInterval}ms, ` + 
			`duration=${playDuration}ms`);
		
		const interval = isContinuous ? undefined : playInterval;
		const duration = isContinuous ? undefined : playDuration;
        AudioPlayer.setBackgroundAudio(true, interval, duration);	
	}
}

export default new SoundPlayer();
