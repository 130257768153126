import React from 'react';
import { useDispatch } from 'react-redux';
import classes from './Navigator.module.css';
import logo from '../../../assets/images/ception-logo-dark-vertical-1.png';
import { setCurrentPage } from '../../../store/actions/core.actions';
import goBackImg from '../../../assets/images/dashboard/go-back.svg';

const Navigator = () => {
	const dispatch = useDispatch();
	
	const handleCloseDashboard = () => {
		dispatch(setCurrentPage({ page: '', isSetUrl: true }));
	}

	return (
		<div className={classes.navigator}>
			<div className={classes.titleBackground}>
				<img alt="logo" src={logo} className={classes.ceptionLogo} />
				<div className={classes.titleContent}>
					<div className={classes.title}>Ception</div>
					<div className={classes.text}>Analysis Dashboard</div>
				</div>
			</div>
			<div className={classes.navigation}>
				<button onClick={() => {}} className={classes.gridItem}>
					Operator Alerts
					<div className={classes.indicator} />
				</button>
				<button onClick={() => {}} className={`${classes.gridItem} ${classes.disabledItem}`}>
					{/* Reports */}
				</button>
				<button onClick={handleCloseDashboard} className={classes.gridItem}> 
					<img alt="Go back" src={goBackImg} className={classes.goBackImage} />
				</button>
			</div>
		</div>
	);
};
export default Navigator;
