import React from 'react';
import useStyles from './verticalReverseAlertBar.css';
import { useSelector, useDispatch } from 'react-redux';
import AlertBox from '../reverseAlertBar/alertBox/AlertBox';
import {
	getAlertSwitches, getAngleAlert, getBermAlert,
	getIsBermVisible,
	getSpeedAlert
} from '../../../store/selectors/mining/mining.selectors';
import { flipAlertSwitch } from '../../../store/actions/mining.actions';
import profileConfig from "../../../core/profiles/profileConfig";

function ReverseAlertBar() {
	const classes = useStyles();
	const speedAlert = useSelector(getSpeedAlert);
	const approachAngleAlert = useSelector(getAngleAlert);
	const midAxleHeightAlert = useSelector(getBermAlert);
	const isBermVisible = useSelector(getIsBermVisible);
	const alertSwitches = useSelector(getAlertSwitches);
	const dispatch = useDispatch();
	const flipSoundSwitch = (selectedAlert) => () => {
		if(!!profileConfig().disableReverseVolumeControl) return;
		dispatch(flipAlertSwitch(selectedAlert));
	};

	return (
		<div className={classes.root}>
			<AlertBox disabled={!isBermVisible} isAlert={speedAlert > 0} label={'SD'}
					  isSoundOn={alertSwitches.speedAlert} soundSwitch={flipSoundSwitch('speedAlert')} short={true}/>
			<AlertBox disabled={!isBermVisible} isAlert={approachAngleAlert > 0} label={'AA'}
					  isSoundOn={alertSwitches.approachAngleAlert}
					  soundSwitch={flipSoundSwitch('approachAngleAlert')} short={true} />
			<AlertBox disabled={!isBermVisible} isAlert={midAxleHeightAlert > 0} label={'BH'}
					  isSoundOn={alertSwitches.midAxleHeightAlert}
					  soundSwitch={flipSoundSwitch('midAxleHeightAlert')} short={true} />
		</div>
	);
}

export default ReverseAlertBar;
