import { makeStyles } from '@material-ui/core/styles';
interface Props {
	isVertical: boolean
}
export default makeStyles({
	root: {
		background: 'rgba(0,0,0,0.6)',
		width: '100%',
		height: '100%',
		position: 'fixed',
		zIndex: 19999,
	},
	// @ts-ignore
	alert: ({isVertical}: Props) => {
		const style = {
			position: 'fixed',
			zIndex: 20000,
			border: '10px solid #FF4D4B',
		};
		if(isVertical){
			style['left'] = '3%';
			style['top'] = '2%';
			style['bottom'] = '2%';
			style['width'] = '94%';
			style['height'] = '96%';
		}
		else{
			style['left'] = '2%';
			style['top'] = '3%';
			style['bottom'] = '3%';
			style['width'] = '96%';
			style['height'] = '94%';
		}
		return style;
	},
	title: {
		position: 'relative',
		top: '10vh',
		textAlign: 'center',
		color: '#FF6665',
		fontSize: '10.5vh',
		fontFamily: 'Mukta Mahee',
		fontWeight: 'bold',
		textShadow: '0 2px 4px rgba(0, 0, 0, 0.24)',
		'-webkit-text-stroke': '1px #051538'
	},
	subTitle: {
		position: 'relative',
		top: '6vh' ,
		textAlign: 'center',
		color: '#FF6665',
		fontSize: '5vh',
		fontFamily: 'Mukta Mahee',
		fontWeight: 'bold',
		textShadow: '0 2px 4px rgba(0, 0, 0, 0.24)',
		'-webkit-text-stroke': '1px #051538'
	},
	// @ts-ignore
	stop: ({isVertical}: Props) => {
		const style = {
			display: 'block',
			marginLeft: 'auto',
			marginRight: 'auto',
			position: 'relative',
			top: 'calc(10%)',
		};
		if(isVertical){
			style['height'] = '30%';
		}
		else{
			style['height'] = '40%';
		}
		return style;
	},
});
