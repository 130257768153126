import React, {useEffect} from 'react';
import AlertInWheel from '../features/alertInWheel/alertInWheel';
import MinMap from '../features/mining-truck/miniMap/MinMap';

const appIsShowAlertsInWheel = process.env.REACT_APP_IS_SHOW_ALERTS_IN_WHEEL;
const isShowAlertsInWheelContent = appIsShowAlertsInWheel && appIsShowAlertsInWheel.trim().toLowerCase();
export const isShowAlertsInWheel = isShowAlertsInWheelContent && isShowAlertsInWheelContent === 'true';
const height = screen.height;
const width = screen.width;
export let isVertical = (height / width) > 1.05;

const obstacleMinSeverityToAlert = parseInt(process.env
    .REACT_APP_OBSTACLE_MINIMUM_SEVERITY_TO_ALERT as string);

export const getHighestSeverityObstacle = obstacles => {
    if (!obstacles || obstacles.length === 0) {
        return undefined;
    }
    const maxSeverityObstacle = obstacles.reduce((prev, curr) =>
        curr.severity > prev.severity ? curr : prev
    );
    if (maxSeverityObstacle.severity < obstacleMinSeverityToAlert) {
        return undefined;
    }
    return maxSeverityObstacle;
};

const showAlert = (obstacles, miniMapSize: number) => {
    const maxSeverityObstacle = getHighestSeverityObstacle(obstacles);
    if (!maxSeverityObstacle || (maxSeverityObstacle.severity === undefined && maxSeverityObstacle.obstacleType === undefined)) {
        return <></>;
    }
    return (
        <AlertInWheel
            size={miniMapSize}
            severity={maxSeverityObstacle.severity}
            type={maxSeverityObstacle.obstacleType}
            description={maxSeverityObstacle.obstacleDescription}
        />
    );
};

export const showMiniMap = (
    sectors,
    obstacles,
    miniMapSize: number,
    miniMapClass: string,
    isShow = true
) => {
    useEffect(() => {
        const height = screen.height;
        const width = screen.width;
        isVertical = (height / width) > 1.05;
    }, [screen.height, screen.width])

    if (!isShow) {
        return <></>
    }

    return (
        <div className={miniMapClass}>
            <MinMap size={miniMapSize} sectors={sectors} obstacles={obstacles}/>
            {isShowAlertsInWheel && showAlert(obstacles, miniMapSize)}
        </div>
    )
};
