import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { useSnackbar, SnackbarContent } from 'notistack';

import useStyles from './snackBar.css';
import olMapService from '../../core/services/ol-map.service';
import { selectFeature, setNewSafetyEventFlag } from '../../store/actions/mining.actions';
import { setMapToStaticState } from '../../store/actions/map.actions';

interface SnackData{
	symbol: string
	label: string
	date: string
	id: string
	position: Array<number>
	isPositionValid: boolean
}

const SnackBar = forwardRef<HTMLDivElement, { id: string | number, data: SnackData | React.ReactNode }>((props, ref) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { closeSnackbar } = useSnackbar();
	const data = (props.data as SnackData);

	const selectItem = (id, position, isPositionValid) =>{
		if(isPositionValid && position){
			dispatch(setMapToStaticState());
			olMapService.setCenter(position);
		}
		dispatch(selectFeature(id));
		dispatch(setNewSafetyEventFlag(false));
		closeSnackbar(props.id);
	};
	return (
		<SnackbarContent ref={ref}>
			<div className={classes.card} onClick={()=>selectItem(data.id, data.position, data.isPositionValid)}>
				<img src={data.symbol} className={classes.symbol}/>
				<span className={classes.label}>{data.label}</span>
				<span className={classes.time}>{data.date}</span>
			</div>
		</SnackbarContent>
	);
});

export default SnackBar;
