import SoundPlayer from './soundPlayer';
import { Falling } from '../definition/Enums';
import { config } from './config';
import {
	AlertData,
	AlertKeyOrUndefined,
	AlertObject,
} from '../interfaces/Sounds';
import { log } from './debug';

const minimumSeverityToPlay =
	(process.env.REACT_APP_OBSTACLE_MINIMUM_SEVERITY_FOR_SOUND &&
		parseInt(
			process.env.REACT_APP_OBSTACLE_MINIMUM_SEVERITY_FOR_SOUND.trim() as string
		)) ||
	0;
log('minimumSeverityToPlay', minimumSeverityToPlay);

class AlertsSoundService {
	private currentSound: string | undefined = undefined;
	private currentAlert: AlertKeyOrUndefined = undefined;

	public constructor() {}

	private getAlertImportance(type: string, severity: number): number {
		const importanceMatrix = config.importanceMatrix;
		const typeImportance = importanceMatrix[type];
		if (!typeImportance) {
			return 0;
		}
		return typeImportance[severity] || 0;
	}

	init() {
		this.initSounds();
		// this.testSounds();
	}

	public initSounds() {
		SoundPlayer.init();
	}

	public onAlerts(alertData: AlertData) {
		if (!SoundPlayer.getIsReady()) {
            log("OnAlert before player is ready!");
			return;
		}
		const mostImportantAlert = this.getMostImportantAlertCalculator(
			alertData
		);
		log('mostImportantAlert', mostImportantAlert);
		const isAlertChanged = this.checkIsAlertChanged(mostImportantAlert);
		log('is alert changed', isAlertChanged);
		if (!isAlertChanged) {
			return;
		}
		const newSoundToPlay = this.getAlertToSoundKey(mostImportantAlert);
		log('newSoundToPlay', newSoundToPlay);
		const isSoundChanged = this.checkIsSoundKeyChanged(newSoundToPlay);
		log('isSoundChanged', isSoundChanged);
		if (!isSoundChanged) {
			return;
		}
		SoundPlayer.play(newSoundToPlay,alertData.offset);
		this.currentAlert = mostImportantAlert;
		this.currentSound = newSoundToPlay;
	}

	public setMuteAndVolume(isMute: boolean, volume: number) {
		SoundPlayer.setMuteAndVolume(isMute, volume);
	}

	private getMostImportantAlertCalculator(
		alertData: AlertData
	): AlertKeyOrUndefined {
		const {
			zoneAlert,
			bermAlert,
			angleAlert,
			speedAlert,
			isGoingReverse,
			fallingState,
			laneAlert,
			obstacles,
			alertSwitches,
		} = alertData;

		if (fallingState === Falling.Alert) {
			return {
				type: 'falling',
				subType: undefined,
				severity: Number.MAX_SAFE_INTEGER,
			};
		}
		const unloadingAlertsTypesBeforeFilter = [
			{ key: 'speed', value: alertSwitches.speedAlert ? speedAlert : 0 },
			{
				key: 'angle',
				value: alertSwitches.approachAngleAlert ? angleAlert : 0,
			},
			{
				key: 'berm',
				value: alertSwitches.midAxleHeightAlert ? bermAlert : 0,
			},
		];
		const unloadingAlertsTypes = unloadingAlertsTypesBeforeFilter.filter(
			obj => obj.value > 0
		);
		const unloadingAlerts: AlertObject[] = isGoingReverse
			? unloadingAlertsTypes.map(obj => ({
					key: {
						type: obj.key,
						subType: undefined,
						severity: obj.value,
					},
					importance: this.getAlertImportance(obj.key, obj.value),
			  }))
			: [];
		const adasAlertsTypes = [
			{ key: 'zone', value: zoneAlert },
			{ key: 'lane', value: laneAlert },
		];
		const adasAlerts: AlertObject[] = !isGoingReverse
			? adasAlertsTypes
					.filter(obj => obj.value > 0)
					.map(obj => ({
						key: { type: obj.key, subType: undefined, severity: 1 },
						importance: this.getAlertImportance(obj.key, 1),
					}))
			: [];
		let obstaclesAlerts: AlertObject[] = [];
		if (obstacles && obstacles.length) {
			obstaclesAlerts = obstacles
				.filter(obj => obj.severity >= minimumSeverityToPlay)
				.map(obj => ({
					key: {
						type: 'obstacle',
						subType: obj.obstacleType,
						severity: obj.severity,
					},
					importance: this.getAlertImportance(
						obj.obstacleType.toString(),
						obj.severity
					),
				}));
		}

		const allAlerts = [
			...unloadingAlerts,
			...adasAlerts,
			...obstaclesAlerts,
		];
		log('allAlerts after importance calc', JSON.stringify(allAlerts));
		if (allAlerts.length === 0) {
			return undefined;
		}
		const mostImportantAlert = allAlerts.reduce((prev, curr) =>
			curr.importance > prev.importance ? curr : prev
		);
		if (mostImportantAlert.importance === 0) {
			return undefined;
		}
		return mostImportantAlert.key;
	}

	private checkIsAlertChanged(
		mostImportantAlert: AlertKeyOrUndefined
	): boolean {
		if (mostImportantAlert && this.currentAlert) {
			return (
				mostImportantAlert.type !== this.currentAlert.type ||
				mostImportantAlert.subType !== this.currentAlert.subType ||
				mostImportantAlert.severity !== this.currentAlert.severity
			);
		} else if (!mostImportantAlert && !this.currentAlert) {
			return false;
		}
		return true;
	}

	private getAlertToSoundKey(alert: AlertKeyOrUndefined): string | undefined {
		if (!alert) {
			return undefined;
		}
		const { type, subType, severity } = alert;
		const possibleKeys = [
			`${type}_${subType}_${severity}`,
			`${type}${subType}${severity}`,
			`${type}_type_${subType}_severity_${severity}`,
			`${type}_type_${subType}_${severity}`,
			`${type}_type_${subType}_severity_${severity}`,
			`${subType}_${severity}`,
			`${subType}${severity}`,
			`${type}_${severity}`,
			`${type}${severity}`,
			`${type}`,
		];
		const soundList = config.soundList.sounds;
		const key = possibleKeys.find(key => key in soundList);
		return key;
	}

	private checkIsSoundKeyChanged(
		newSoundToPlay: string | undefined
	): boolean {
		return this.currentSound !== newSoundToPlay;
	}
}

export default new AlertsSoundService();
