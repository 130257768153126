import React from 'react';
import useStyles from './reverseAlertBar.css';
import { useSelector, useDispatch } from 'react-redux';
import AlertBox from './alertBox/AlertBox';
import {
	getAlertSwitches, getAngleAlert, getBermAlert,
	getIsBermVisible,
	getSpeedAlert
} from '../../../store/selectors/mining/mining.selectors';
import { flipAlertSwitch } from '../../../store/actions/mining.actions';

function ReverseAlertBar() {
	const classes = useStyles();
	const speedAlert = useSelector(getSpeedAlert);
	const approachAngleAlert = useSelector(getAngleAlert);
	const midAxleHeightAlert = useSelector(getBermAlert);
	const isBermVisible = useSelector(getIsBermVisible);
	const alertSwitches = useSelector(getAlertSwitches);
	const dispatch = useDispatch();
	const flipSoundSwitch = (selectedAlert) => () => {
		dispatch(flipAlertSwitch(selectedAlert));
	};

	return (
		<div className={classes.root}>
			<div />
			<AlertBox disabled={!isBermVisible} isAlert={speedAlert > 0} symbol={'SD'} label={'SPEED ALERT'}
					  isSoundOn={alertSwitches.speedAlert} soundSwitch={flipSoundSwitch('speedAlert')} />
			<AlertBox disabled={!isBermVisible} isAlert={approachAngleAlert > 0} symbol={'AA'} label={'APPROACH ANGLE'}
					  isSoundOn={alertSwitches.approachAngleAlert}
					  soundSwitch={flipSoundSwitch('approachAngleAlert')} />
			<AlertBox disabled={!isBermVisible} isAlert={midAxleHeightAlert > 0} symbol={'BH'} label={'BERM HEIGHT'}
					  isSoundOn={alertSwitches.midAxleHeightAlert}
					  soundSwitch={flipSoundSwitch('midAxleHeightAlert')} />
			<div />
		</div>
	);
}

export default ReverseAlertBar;
