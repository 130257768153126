import YAML from 'yamljs';
import Axios from 'axios';
import generalConfig from '../defaultConfigs/config.json';
import soundList from '../defaultConfigs/soundList.json';
import importanceMatrix from '../defaultConfigs/importanceMatrix.json';
import hazardAndSafetyIcons from '../defaultConfigs/hazardAndSafetyIcons.json';
import hazardAndSafetyLabels from '../defaultConfigs/hazardAndSafetyLabels.json';
import hazardAndSafetyColors from '../defaultConfigs/hazardAndSafetyColors.json';
import vehiclesIcons from '../defaultConfigs/vehiclesIcons.json';

const publicFolder = process.env.PUBLIC_URL;
const configPath = `${publicFolder}/config/`;

interface IGeneralConfig {
	video: {
		isMinimized: boolean;
		isAutoPlay: boolean;
		isLoop: boolean;
		isShowControls: boolean;
		timeDeltaForWheelUpdate: number;
	},
	keycloak: {
		url: string;
		clientId: string;
		realm: string;
	},
	volume: {
		disableVolumeControl: boolean;
	},
	dashboard: {
		queryDefaults: any;
		showDataLabels: {
			overallSeverityBySite: boolean;
			overallEventsByType: boolean;
			overallEventsOverTime: boolean;
			siteEventsBySeverity: boolean;
			siteEventsByType: boolean;
			siteEventsByHour: boolean;
		},
		isShowAllHours: boolean;
		defaultColor: string,
        hourBarColor: string,
		siteColors: string[]
	},
	obstacles: {
		clearTimeoutMS: number
	},
	sectors: {
		sectorsAmount: number
	},
	reverseData: {
		clearTimeoutMS: number
	},
	sounds: {
		isWaitForInteraction: boolean;
		waitForInteractionInterval: number;
		isPlayBackgroundSound: boolean;
		isBackgroundSoundContinuous: boolean;
		backgroundSoundPlayInterval: number,
		backgroundSoundPlayDuration: number;
	}
}

interface IEventLabels {
	hazards: string[];
	safeties: string[];
}

interface IEventColors {
	hazards: string[];
	safeties: string[];
}

interface SoundDef {
	src: string;
	loop: boolean;
	speed: number;	
}

interface ISounds{
	sounds: { [key: string]: SoundDef };
	soundsToNotCut: string[];
}

interface IConfig {
	isInitialized: boolean;
	isError: boolean;
	general: IGeneralConfig;
	soundList: ISounds;
	importanceMatrix: any;
	hazardAndSafetyIcons: any;
	hazardAndSafetyLabels: IEventLabels;
	hazardAndSafetyColors: IEventColors;
	isRequiredLogin: boolean;
	isMultiSite: boolean;
	siteKey?: string;
	vehiclesIcons: any;
}

export const config: IConfig = {
	isInitialized: false,
	isError: false,
	general: generalConfig,
	soundList: { sounds: {}, soundsToNotCut:[] },
	importanceMatrix: undefined,
	hazardAndSafetyIcons: undefined,
	hazardAndSafetyLabels: { hazards: [], safeties: [] },
	hazardAndSafetyColors: { hazards: [], safeties: [] },
	isRequiredLogin: false,
	isMultiSite: false,
	siteKey: undefined,
	vehiclesIcons: undefined
};

const loadConfig = async (configKey: string, filename: string, type: string, defaultConfig: any) => {
	try {
		const [extension, parser] =
			type === 'yaml' ? ['yaml', YAML.parse] : ['json', (res: any) => res];
		const path = `${configPath}${filename}.${extension}`;
		const { data } = await Axios.get(path);
		if (!data) {
			throw new Error("failed to fetch");
		}
		config[configKey] = { ...defaultConfig,  ...parser(data) };
	} catch (e) {
		console.log(`Error loading config of ${configKey}, reason:`, e);
		config.isError = true;
		config[configKey] = { ...defaultConfig };
	}
	Object.seal(config[configKey]);
	Object.freeze(config[configKey]);
};

export const initConfig = async () => {
	const startTime = Date.now();
	const configs: Array<[string, string, string, any]> = [
		['general', 'config', 'yaml', generalConfig],
		['soundList', 'soundList', 'json', soundList],
		['importanceMatrix', 'importanceMatrix', 'json', importanceMatrix],
		['hazardAndSafetyIcons', 'hazardAndSafetyIcons', 'json', hazardAndSafetyIcons],
		['hazardAndSafetyLabels', 'hazardAndSafetyLabels', 'json', hazardAndSafetyLabels],
		['hazardAndSafetyColors', 'hazardAndSafetyColors', 'json', hazardAndSafetyColors],
		['vehiclesIcons', 'vehiclesIcons', 'json', vehiclesIcons]
	];
	// @ts-ignore
	await Promise.allSettled(configs.map(conf => loadConfig(...conf)));
	config.isRequiredLogin = process.env.REACT_APP_REQUIRE_LOGIN ? 
		process.env.REACT_APP_REQUIRE_LOGIN.trim().toLowerCase() === 'true' : false;
	config.isMultiSite = process.env.REACT_APP_IS_MULTI_SITE ? 
		process.env.REACT_APP_IS_MULTI_SITE.trim().toLowerCase() === 'true' : false;
	config.siteKey = process.env.REACT_APP_SITE_KEY;
	config.isInitialized = true;
	Object.seal(config);
	Object.freeze(config);
	const totalTimeSec = (Date.now() - startTime) / 1000;
	console.debug(`Initializing config took: ${(Math.round(totalTimeSec * 100) / 100)} seconds`)
};
