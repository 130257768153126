import classes from "./StatusAlert.module.css";
import {
    getMineceptStatus_label,
    getMineceptStatus_status, getMineceptStatus_text
} from "../../../store/selectors/mining/mining.selectors";
import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {confirmAlert} from "react-confirm-alert"; // Import
import connectingIcon from '../../../assets/images/connecting.svg'
import systemAlertIcon from '../../../assets/images/systemAlertIcon.svg'
import {isVertical} from "../../../services/obstacles";
import {limitStringLength} from "../../../core/auxilary/strings";

const emptyFunction = () => {};

const StatusAlert = () => {
    const status = useSelector(getMineceptStatus_status);
    const title = useSelector(getMineceptStatus_label);
    const text = useSelector(getMineceptStatus_text);
    const onCloseFunc = useRef(emptyFunction);
    useEffect(() => {
        // if onClose then call it and reset it
        if (onCloseFunc.current !== emptyFunction) {
            onCloseFunc.current();
            onCloseFunc.current = emptyFunction;
        }
        const isConnecting = status==3;
        const isShowModal = status !== 1 && (text || title);
        const isShowContent = isShowModal ||isConnecting
        if (isShowContent) {
            confirmDialog();
        }
    }, [status]);

    const Notification = () => (<div className={isVertical ? classes.NotificationVertical : classes.Notification}>
        {text &&
            <React.Fragment>
            <img className={classes.NotificationIcon} src={systemAlertIcon} alt='system alert icon'/>
            <p className={isVertical ? classes.NotificationContentVertical : classes.NotificationContent}>{limitStringLength(text,60)}</p>
            </React.Fragment>
        }
    </div>);

    const fillContent = () => {
        if (status === 3) {
            return <div className={isVertical ? classes.ConnectingVertical : classes.Connecting}>
                <div className={isVertical ? classes.ConnectingContentVertical : classes.ConnectingContent}>
                    <img className={classes.LoadingIcon} src={connectingIcon} alt='connecting icon'/>
                    <p className={classes.ConnectingTitle}>Connecting...</p>
                </div>
            </div>
        }
        if (text || title) {
            return <div className={isVertical ? classes.RectangleVertical : classes.Rectangle}>
                {title && <p className={isVertical ? classes.verticalTitle : classes.title}>{title}</p>}
                {text && <p className={isVertical ? classes.verticalText : classes.text}>{text}</p>}
            </div>;
        }
    }

    const confirmDialog = () => {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({onClose}) => {
                onCloseFunc.current = onClose;
                return (
                    <div className={classes.backdrop}>
                        {fillContent()}
                    </div>

                );
            },
        });
    };

    return (status === 1 && (text || title)) ? <Notification/> : <></>;

};
export default StatusAlert;
