import { Subject } from 'rxjs';
import { sourceMapOptions } from '../../definition/source-map-options';
import { selector } from '../../index';
import { selector$ } from '../store';


export const getAudioState = (state): boolean => state.map.audioStateOn;


export const getMapStateFollowRoute = (): boolean => {
	return selector('map.followRoute');
};

export const getMapStaticState = (): boolean => {
	return selector('map.staticState');
};

export const getMapStateRotation = (): number => {
	return selector('map.mapRotation');
};

export const getMapStaticState$ = (): Subject<boolean> => {
	return selector$('map.staticState');
};


export const getMapSources = (): Subject<sourceMapOptions[]> => {
	return selector$('map.mapSources');
};

export const getMapCenter = (): any => {
	return selector('map.center');
};

export const getMapResolution = (): number => {
	return selector('map.resolution');
};
