export const Infinity = 3.154e7; // play for a year

export enum AudioSoundEvent {
	completed = 'completed',
	looped = 'looped',
}

export type AudioSoundCallback = (event: AudioSoundEvent, id: string) => void;

export class AudioSound {
	private id: string;
	private context: AudioContext;
	private buffer: AudioBuffer;
	private gainNode: GainNode;
	private sourceNode: AudioBufferSourceNode | undefined;
	private duration: number;
	private callback: AudioSoundCallback | undefined;
	private loopEndHandler: number | undefined;
	private isPlaying: boolean;

	constructor(
		id: string,
		context: AudioContext,
		masterGain: GainNode,
		buffer: AudioBuffer,
		callback?: AudioSoundCallback
	) {
		this.id = id;
		this.context = context;
		this.buffer = buffer;
		this.gainNode = masterGain;
		this.sourceNode = undefined;
		this.duration = buffer.duration;
		this.callback = callback;
		this.loopEndHandler = undefined;
		this.isPlaying = false;
	}

	play(isLoop?: boolean, rate?: number, offset?: number) {
		this.stop();
		this.sourceNode = this.context.createBufferSource();
		this.sourceNode.buffer = this.buffer;
		this.sourceNode.playbackRate.setValueAtTime(
			rate ? rate : 1.0,
			this.context.currentTime
		);
		this.sourceNode.loop = Boolean(isLoop);
		this.sourceNode.connect(this.gainNode);
		this.registerCallbacks(this.sourceNode.loop);
		const playStop = isLoop ? Infinity : this.duration;
		//if offset - calc by it
		//  (offset % currentPlayer.duration))
		this.sourceNode.start(0, offset ? ( offset % this.duration ) : 0, playStop);
		this.isPlaying = true;
	}

	private registerCallbacks(isLoop: boolean) {
		if (!this.sourceNode) {
			return;
		}
		const callback = this.callback;
		if (!callback) {
			return;
		}
		if (!isLoop) {
			this.sourceNode.onended = ev => {
				callback(AudioSoundEvent.completed, this.id);
			};
		} else {
			// @ts-ignore
			this.loopEndHandler = setInterval(() => {
				callback(AudioSoundEvent.looped, this.id);
			}, this.duration * 1000);
		}
	}

	stop() {
		if (this.loopEndHandler) {
			clearInterval(this.loopEndHandler);
			this.loopEndHandler = undefined;
		}
		if (!this.sourceNode) {
			return false;
		}
		try {
			this.sourceNode.stop(0);
			this.sourceNode.disconnect();
			this.isPlaying = false;
			return true;
		} catch {
			return false;
		} finally {
			this.sourceNode = undefined;
		}
	}

	getIsPlaying() {
		return this.isPlaying;
	}

	getDuration() {
		return this.duration;
	}
}
