class EventCallback {
	id: any;
	callback: any;
	isOnce: boolean;

	constructor(callback: any, isOnce?: boolean, id?: any) {
		this.id = id;
		this.callback = callback;
		this.isOnce = Boolean(isOnce);
	}
}

export class EventEmitter {
	private bindInstance: any;
	private events: { [key: string]: EventCallback[] };

	constructor(bindInstance?: any) {
		this.bindInstance = bindInstance;
		this.events = {};
	}

	on(event: string, callback: any, isOnce?: boolean, id?: any) {
		if (!(event in this.events)) {
			this.events[event] = [];
		}

		this.events[event].push(new EventCallback(callback, isOnce, id));
	}

	once(event: string, callback: any, id?: any) {
		this.on(event, callback, true, id);
	}

	off(event: string, callback?: any, id?: any) {
		if (!(event in this.events)) {
			return false;
		}

		if (!callback && !Boolean(id)) {
			return false;
		}

		const callbacksBeforeFilter = this.events[event];
		const callbacksAfterFilter = callbacksBeforeFilter.filter(x => {
			if (callback) {
				return x.callback !== callback;
			}
			return x.id !== id;
		});
		if (callbacksAfterFilter.length === callbacksAfterFilter.length) {
			return false;
		}
		this.events[event] = callbacksAfterFilter;
		return true;
	}

	emit(event: string, ...args: any[]) {
		if (!(event in this.events)) {
			return false;
		}
		const originalCallbacks = this.events[event];
		originalCallbacks.forEach(callbackInfo => {
			if (!callbackInfo.callback) {
				return;
			}
			setTimeout(
				() => callbackInfo.callback.call(this.bindInstance, ...args),
				0
			);
			const callbackAfterFilter = originalCallbacks.filter(
				x => !x.isOnce
			);
			if (callbackAfterFilter.length !== originalCallbacks.length) {
				this.events[event] = callbackAfterFilter;
			}
			return true;
		});

		return true;
	}
}
