import React, { useEffect } from 'react';
import classes from './Dashboard.module.css';
import SiteChart from './SiteCharts/SiteChart';
import OverallCharts from "./OverallCharts/OverallCharts";
import Navigator from './Navigator/Navigator';
import Options from './Options/Options';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardData } from '../../store/selectors/mining/mining.selectors';
import { DASHBOARD_ALL_SITES_OPTION, IMineceptDashboard } from '../../store/reducers/mining/IMiningReducerState';
import { CircularProgress } from '@material-ui/core';
import ErrorMessages from '../error-messages/error-growl/ErrorGrowl';
import { resetErrorMessages, sendErrorMessage } from '../error-messages/actions/errorMessages.actions';
import { IErrorSeverity } from '../../definition/error-enum';

const Dashboard = () => {
    const dispatch = useDispatch();
	const { site, data, isLoading, isError }: IMineceptDashboard = useSelector(getDashboardData);
	const isDataAccessible = data && data.graphs && !isLoading && !isError;
    const isOverall = site === DASHBOARD_ALL_SITES_OPTION;

    useEffect(() => {
        if (isError) {
            const message = {
                severity: IErrorSeverity.ERROR,
                summary: 'Failed to retrieve dashboard',
                detail: '',
            };
            dispatch(sendErrorMessage(message));
        } else {
            dispatch(resetErrorMessages());
        }
    }, [isError]);

	return (
        <div className={classes.dashboard}>
            <ErrorMessages />
            <Navigator />
            <Options />
            <div className={classes.charts}>
                {isDataAccessible && isOverall && <OverallCharts/>}
                {isDataAccessible && !isOverall && <SiteChart/>}
                {isLoading && <CircularProgress size={'60vh'} style={{'color': 'white'}} />}
            </div>
        </div>
	);
};

export default Dashboard;
