import { Fill, Stroke, Style } from 'ol/style';

const colorMap = [
	'#514EE4',
	'#6B68EC',
	'#7185EE',
	'#85A7FE',
	'#7AB9F6',
	'#53C8EB',
	'#56CFE1',
	'#64DFDF',
	'#72EFDD',
	'#80FFDB',
	'#BCFFEC',
	'#E0FFF4',
	'#FFFFFF',
];

const polylineStyle = new Style({
	stroke: new Stroke({
		color: '#F9E40F',
		width: 3,
		lineDash: [8,8],
	}),
	fill: new Fill({
		color: '#F9E40F',
	}),
});

const heightStyles = colorMap.map((color) => new Style({
	stroke: new Stroke({
		color,
		width: 2,
		lineDash: [1,1],
	}),
	fill: new Fill({
		color,
	}),
}));

const otherStyle = new Style({
	stroke: new Stroke({
		color: 'rgba(148,23,29,0.5)',
		width: 2,
		lineDash: [1,1],
	}),
	fill: new Fill({
		color: 'rgba(148,23,29,0.5)',
	}),
});

const styleFunction = (feature) => {
	const entityType = feature.values_.entity;
	if (entityType === 'polyLine') {
		return polylineStyle;
	} else if (entityType === 'heightLine') {
		const height = Math.max(Math.min(feature.values_.height, 2), 0);
		const index = Math.round(height * 4);
		return heightStyles[index];
	}
	return otherStyle;
};

export default styleFunction;
