import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {getVolume} from '../../../store/selectors/core.selector';
import {
	getAlertSwitches,
	getAngleAlert,
	getBermAlert,
	getIsFalling,
	getIsGoingReverse,
	getObstacleAlerts,
	getSpeedAlert,
	getVideoSoundAudioOn,
	getVideoSoundOffset,
} from '../../../store/selectors/mining/mining.selectors';
import {getAdasZones, getLaneAlert} from '../selectors/adas-alerts.selectors';

import {config} from '../../../services/config';

import AlertsSoundService from '../../../services/alertsSoundService';
import {getAudioState} from '../../../store/selectors/map.selectors';

function AlertsSound() {
	const volume = useSelector(getVolume);
	const isAudioOn = useSelector(getAudioState);
	const zoneAlert = useSelector(getAdasZones);
	const bermAlert = useSelector(getBermAlert);
	const angleAlert = useSelector(getAngleAlert);
	const speedAlert = useSelector(getSpeedAlert);
	const isGoingReverse = useSelector(getIsGoingReverse);
	const fallingState = useSelector(getIsFalling);
	const laneAlert = useSelector(getLaneAlert);
	const alertSwitches = useSelector(getAlertSwitches);
	const obstacles = useSelector(getObstacleAlerts);
	const videoSoundOffset = useSelector(getVideoSoundOffset);
	const videoSoundAudioOn = useSelector(getVideoSoundAudioOn);

	useEffect(() => {
		if (!config.isInitialized || !config.soundList.sounds) {
			return;
		}
		AlertsSoundService.init();
	}, []);

	useEffect(() => {
		AlertsSoundService.onAlerts({
			zoneAlert: zoneAlert.size,
			bermAlert,
			angleAlert,
			speedAlert,
			isGoingReverse,
			fallingState,
			laneAlert: laneAlert.size,
			obstacles,
			alertSwitches,
			offset:videoSoundOffset
		});
	}, [
		zoneAlert,
		bermAlert,
		angleAlert,
		speedAlert,
		isGoingReverse,
		fallingState,
		laneAlert,
		obstacles,
		alertSwitches,
		videoSoundOffset
	]);
	useEffect(() => {
		if (typeof videoSoundAudioOn == "boolean") {
			videoSoundAudioOn ? AlertsSoundService.setMuteAndVolume(!isAudioOn, volume) : AlertsSoundService.setMuteAndVolume(true, volume);
			return;
		}
		AlertsSoundService.setMuteAndVolume(!isAudioOn, volume);
	}, [isAudioOn, volume, videoSoundAudioOn]);

	return <></>;
}

export default AlertsSound;
