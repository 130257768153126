export const Infinity = 3.154e7; // play for a year

export class NoiseSound {
	private context: AudioContext;
	private gainNode: GainNode;
	private buffer: AudioBuffer;
	private sourceNode: AudioBufferSourceNode | undefined;
	private isOn: boolean;
	private playTimeoutHandler: number | undefined;

	constructor(context: AudioContext, masterGain: GainNode) {
		this.context = context;
		this.gainNode = context.createGain();
		this.gainNode.gain.setValueAtTime(0.0001, context.currentTime);
		this.gainNode.connect(masterGain);
		this.sourceNode = undefined;
		this.isOn = false;
		this.playTimeoutHandler = undefined;

		const bufferSize = context.sampleRate * 2;
		this.buffer = context.createBuffer(1, bufferSize, context.sampleRate);
		const output = this.buffer.getChannelData(0);
		for (let i = 0; i < bufferSize; i++) {
			output[i] = Math.random() * 2 - 1;
		}
	}

	play(interval?: number, duration?: number, rate?: number, offset?: number) {
		this.stop();

		const isContinuous = !interval || !duration;

		this.sourceNode = this.context.createBufferSource();
		this.sourceNode.buffer = this.buffer;
		this.sourceNode.playbackRate.setValueAtTime(
			rate ? rate : 1.0,
			this.context.currentTime
		);
		this.sourceNode.loop = true;
		this.sourceNode.connect(this.gainNode);
		const playDuration = isContinuous ? Infinity : duration;
		this.sourceNode.start(0, offset ? offset : 0, playDuration);
		this.isOn = true;

		if (!isContinuous) {
			this.playTimeoutHandler = setTimeout(() => {
				this.playTimeoutHandler = undefined;
				this.play(interval, duration, rate, offset);
			}, interval);
		}
	}

	stop() {
		if (this.playTimeoutHandler) {
			clearTimeout(this.playTimeoutHandler);
		}
		if (!this.sourceNode) {
			return false;
		}
		try {
			this.sourceNode.stop(0);
			this.sourceNode.disconnect();
			this.isOn = false;
			return true;
		} catch {
			return false;
		} finally {
			this.sourceNode = undefined;
		}
	}

	getIsOn() {
		return this.isOn;
	}
}
