import { useEffect, useRef } from "react";

const useAccurateTimer = (interval: number, callback: (latency: number) => void) => {
    let isRunning = useRef(false);
    let timeoutHandler = useRef<NodeJS.Timeout>();
    let nextExpectedTime = useRef(0);

    useEffect(() => {
        return () => stopTimer();
    }, []);

    const onTimer = () => {
        timeoutHandler.current = undefined;
        if (!isRunning) {
            return;
        }

        const now = Date.now();
        const expectedTime = nextExpectedTime.current;
        const latency = now - expectedTime;
        const nextInterval = Math.max(0, interval - latency);
        nextExpectedTime.current = now + nextInterval;
        
        timeoutHandler.current = setTimeout(onTimer, nextInterval);
        callback(latency);
    }

    const startTimer = () => {
        isRunning.current = true;
        nextExpectedTime.current = Date.now() + interval;
        timeoutHandler.current = setTimeout(onTimer, interval);
    }

    const stopTimer = () => {
        isRunning.current = false;
        nextExpectedTime.current = 0;
        if (timeoutHandler.current) {
            clearTimeout(timeoutHandler.current);
            timeoutHandler.current = undefined;
        }
    }

    return { startTimer, stopTimer };
};

export default useAccurateTimer;