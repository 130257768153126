import { Subject } from 'rxjs';
import { createSelector } from 'reselect';
import { selector$ } from '../../store';
import { getHazardLabel, getSafetyEventLabel } from '../../../core/services/minecept/auxilary';
import { FeatureTypes } from '../../../definition/features';
import { SortBy } from '../../../definition/Enums';
import { getSafetyEventIcon } from '../../../core/services/icons/safetyEventIcons';
import { getHazardIcon } from "../../../core/services/icons/obstacleIcons";
import { IMineceptDashboard, IMineceptStatus, IObstacle } from '../../reducers/mining/IMiningReducerState';
import { isSeverityZeroWarn } from '../../../definition/severity';

export const getIsFalling = (state) => state.mining.isFalling;
export const getIsGoingReverse = (state) => state.mining.isGoingReverse;
export const getIsBermVisible = (state) => state.mining.isBermVisible;
export const getReverseData = (state) => state.mining.reverseData;
export const getAlertSwitches = (state) => state.mining.alertSwitches;
export const getObstacleAlerts = (state) => state.mining.obstacles;

export const getIsSevereObstacleAlert = (state) => !!state.mining.obstacles.filter(obstacle=>obstacle.severity > 2).length;
export const getObstacleTooltipData = (state) => state.mining.tooltips.obstacle;
export const getVehicleTooltipData = (state) => state.mining.tooltips.vehicle;
export const getWarningSectors = (state) => state.mining.warningSectors;
export const getMineceptStatus = (state) => state.mining.mineceptStatus;
export const getSafetyEvents = (state) => state.mining.safetyEvents;
export const getHazards = (state) => state.mining.hazards;
export const getSelectedFeatureId = (state) => state.mining.selected;
export const getControls = (state) => state.mining.controls;
export const getMultiSelected = (state) => state.mining.multiSelect;
const getFlags = (state) => state.mining.flags;

export const getIsMultiSelected = createSelector([getMultiSelected],(multiSelect) => !!multiSelect.length);
export const getSpeedAlert = createSelector([getReverseData],(reverseData) => reverseData.SpeedAlert);
export const getAngleAlert = createSelector([getReverseData],(reverseData) => reverseData.ApproachAngleAlert);
export const getBermAlert = createSelector([getReverseData],(reverseData) => reverseData.MidAxleHeightAlert);

export const getTypesOfExistingHazards = createSelector([getHazards],(arr) => arr.reduce((prev,curr)=>{
	prev[curr.obstacle_type] = true;
	return prev;
},{}));
export const getTypesOfExistingEvents = createSelector([getSafetyEvents],(arr) => arr.reduce((prev,curr)=>{
	prev[curr.type_of_event] = true;
	return prev;
},{}));

export const getSafetyEventsControls = createSelector([getControls],controls => controls.safetyEvents);
export const getSafetyEventsSortBy = createSelector([getSafetyEventsControls],controls => controls.sortBy);
export const getSafetyEventsArchive = createSelector([getSafetyEventsControls],controls => controls.archive);
export const getSafetyEventsFilters = createSelector([getSafetyEventsControls],controls => controls.filters);
export const getAreEventFiltersActivated = createSelector([getSafetyEventsFilters],filters => {
	return filters.filterStart ||
		filters.filterEnd ||
		filters.severity.length !== (isSeverityZeroWarn ? 4 : 3) ||
		filters.type.length !== 100 ||
		filters.archive;
});

export const getHazardControls = createSelector([getControls],controls => controls.hazards);
export const getHazardSortBy = createSelector([getHazardControls],controls => controls.sortBy);
export const getHazardArchive = createSelector([getHazardControls],controls => controls.archive);
export const getHazardFilters = createSelector([getHazardControls],controls => controls.filters);
export const getAreHazardFiltersActivated = createSelector([getHazardFilters],filters => {
	return 	filters.filterStart ||
			filters.filterEnd ||
			filters.severity.length !== (isSeverityZeroWarn ? 4 : 3) ||
			filters.type.length !== 100 ||
			filters.archive;
});

export const getFilteredSafetyEvents = createSelector(
	[getSafetyEvents, getSafetyEventsFilters],
	(safetyEvents, filters) => {
		const severityArray = new Array(4).fill(false);
		filters.severity.forEach(s=>severityArray[s]=true);

		const typeArray = new Array(100).fill(false);
		filters.type.forEach(t=>typeArray[t]=true);

		let filtered = safetyEvents.filter(event =>{
			if(!severityArray[event.severity]) return false;
			return !!typeArray[event.type_of_event];
		});

		if(filters.filterStart){
			const startTime = filters.startTime.getTime() / 1000;
			filtered = filtered.filter(event => event.creation_time > startTime);
		}

		if(filters.filterEnd){
			const endTime = filters.endTime.getTime() / 1000;
			filtered = filtered.filter(event => event.creation_time < endTime);
		}

		if (!filters.archive) {
			filtered = filtered.filter(x => x.is_active);
		}

		if (filters.filterVehicles) {
			filtered = filtered.filter(x => filters.vehicles.includes(x.created_by || ''));
		}

		return filtered;
	}
);

export const getSortedSafetyEvents = createSelector(
	[getFilteredSafetyEvents,getSafetyEventsSortBy],
	(safetyEvents, sortBy) => {
		const allSafetyEvents = [...safetyEvents];
		const sortedByTime = [...allSafetyEvents.sort((a,b)=>b.creation_time - a.creation_time)];
		switch(sortBy){
			case SortBy.time:
				return sortedByTime;
			case SortBy.severity:
				return [...sortedByTime.sort((a,b)=>b.severity-a.severity)];
			case SortBy.type:
				return [...sortedByTime.sort((a,b)=>b.type_of_event-a.type_of_event)];
			case SortBy.vehicle:
				return [...sortedByTime.sort((a,b)=>(a.created_by || '').localeCompare(b.created_by || ''))];
		}
		return safetyEvents;
	});

export const getSafetyEventIds = createSelector([getFilteredSafetyEvents],(events) => events.map(event=>event.id));
export const getMultiSelectedSafetyEvents = createSelector([getMultiSelected,getSafetyEventIds],(multiSelect, eventIds) => multiSelect.filter(id=>eventIds.includes(id)));
export const getAreAllSafetyEventsSelected = createSelector([getMultiSelectedSafetyEvents,getSafetyEventIds],(multiSelect, eventIds) => eventIds.every(id => multiSelect.includes(id)));


export const getFilteredHazards = createSelector(
	[getHazards,getHazardSortBy,getHazardFilters],
	(hazards, sortBy, filters) =>{
		const severityArray = new Array(4).fill(false);
		filters.severity.forEach(s=>severityArray[s]=true);

		const typeArray = new Array(100).fill(false);
		filters.type.forEach(t=>typeArray[t]=true);

		let filtered = hazards.filter(hazard =>{
			if(!severityArray[hazard.severity]) return false;
			return !!typeArray[hazard.obstacle_type];
		});

		if(filters.filterStart){
			const startTime = filters.startTime.getTime() / 1000;
			filtered = filtered.filter(hazard => hazard.last_seen_time > startTime);
		}

		if(filters.filterEnd){
			const endTime = filters.endTime.getTime() / 1000;
			filtered = filtered.filter(hazard => hazard.last_seen_time < endTime);
		}

		if (!filters.archive) {
			filtered = filtered.filter(x => x.is_active);
		}

		if (filters.filterVehicles) {
			filtered = filtered.filter(x => filters.vehicles.includes(x.created_by || ''));
		}

		return filtered;
	}
);

export const getSortedHazards = createSelector(
[getFilteredHazards,getHazardSortBy],
(hazards, sortBy) =>{
	const allHazarts = [...hazards];
	const sortedByTime = [...allHazarts.sort((a,b)=>b.creation_time - a.creation_time)];
	switch(sortBy){
		case SortBy.time:
			return sortedByTime;
		case SortBy.severity:
			return [...sortedByTime.sort((a,b)=>b.severity-a.severity)];
		case SortBy.type:
			return [...sortedByTime.sort((a,b)=>b.obstacle_type-a.obstacle_type)];
		case SortBy.vehicle:
			return [...sortedByTime.sort((a,b)=>(a.created_by || '').localeCompare(b.created_by || ''))];
	}
	return hazards;
});

export const getHazardIds = createSelector([getFilteredHazards],(hazards) => hazards.map(hazard=>hazard.id));
export const getMultiSelectedHazards = createSelector([getMultiSelected,getHazardIds],(multiSelect, hazardIds) => multiSelect.filter(id=>hazardIds.includes(id)));
export const getAreAllHazardsSelected = createSelector([getMultiSelectedHazards,getHazardIds],(multiSelect, hazardIds) => hazardIds.every(id => multiSelect.includes(id)));

export const getSelectedFeature = createSelector(
	[getSelectedFeatureId, getFilteredSafetyEvents, getFilteredHazards],
	(id, safetyEvents, hazards) => {
		if(!id) return null;
		const safetyEvent = safetyEvents.find(safetyEvent=> safetyEvent.id === id);
		if(safetyEvent) return {...safetyEvent,featureType:FeatureTypes.safetyEvent}
		const hazard= hazards.find(hazard=> hazard.id === id);
		if(hazard) return {...hazard,featureType:FeatureTypes.hazard}
		return null;
	}
);

export const getSafetyEventsForTable = createSelector(
	[getSortedSafetyEvents],
	(safetyEvents) => safetyEvents.map(safetyEvent=>{
		const symbol = getSafetyEventIcon(safetyEvent.type_of_event,safetyEvent.severity);
		return{
			id:safetyEvent.id,
			date: safetyEvent.creation_time,
			label: getSafetyEventLabel(safetyEvent.type_of_event, safetyEvent.event_description),
			location: [safetyEvent.longitude,safetyEvent.latitude],
			symbol,
			isActive: safetyEvent.is_active
		}
	})
);

export const getSafetyEventsVehicles = createSelector(
	[getSafetyEvents],
	(safetyEvents) => Object.entries(safetyEvents.reduce((agg, curr) => {
		const id = curr.created_by || '';
		if (agg[id]) {
			agg[id] += 1;
		} else {
			agg[id] = 1;
		}
		return agg;
	}, {}))
	.map(([id, count]) => ({ id, count }))
	.sort((a, b) => a.id.localeCompare(b.id))
)

export const getHazardsForTable = createSelector(
	[getSortedHazards],
	(hazards) => hazards.map(hazard=>{
		const symbol = getHazardIcon(hazard.obstacle_type, hazard.severity);
		return{
			id: hazard.id,
			date: hazard.creation_time,
			label: getHazardLabel(hazard.obstacle_type, hazard.obstacle_description),
			location: [hazard.longitude,hazard.latitude],
			symbol,
			isActive: hazard.is_active
		}
	})
);

export const getHazardsVehicles = createSelector(
	[getHazards],
	(hazards) => Object.entries(hazards.reduce((agg, curr) => {
		const id = curr.created_by || '';
		if (agg[id]) {
			agg[id] += 1;
		} else {
			agg[id] = 1;
		}
		return agg;
	}, {}))
	.map(([id, count]) => ({ id, count }))
	.sort((a, b) => a.id.localeCompare(b.id))
)

export const getNewSafetyEventFlag = createSelector([getFlags],flags => flags.newSafetyEvent);
export const getNewHazardFlag = createSelector([getFlags],flags => flags.newHazard);
export const getPlayedEvent = (state) => state.mining.playedEvent;

export const getIsGoingReverse$ = (): Subject<boolean> => {
	return selector$('mining.isGoingReverse');
};

export const getMineceptLayers$ = (): Subject<any> => {
	return selector$('mining.mineceptLayers');
};

export const getMineceptStatus$ = (): Subject<IMineceptStatus> => {
	return selector$('mining.mineceptStatus');
};
export const getMineceptStatus_status = (state) => Math.min(state.mining.mineceptStatus.status,3);
export const getMineceptStatus_label = (state) => state.mining.mineceptStatus.label.trim();
export const getMineceptStatus_text = (state) => state.mining.mineceptStatus.text.trim();

export const getSites = (state) => state.mining.multiSite.sites;
export const getSelectedSite = (state) => state.mining.multiSite.selectedSite;
export const getSelectedSiteName = (state) => {
	const selectedSite = state.mining.multiSite.selectedSite;
	const siteData = state.mining.multiSite.sites.find(x => x.id === selectedSite);
	if (siteData) {
		return siteData.name;
	}
	return undefined;
}

export const getDashboardData = (state): IMineceptDashboard => state.mining.dashboard;
export const getDashboardGraphs = (state) => state.mining.dashboard && 
	state.mining.dashboard.data && state.mining.dashboard.data.graphs;
export const getDashboardRange = (state) => state.mining.dashboard && 
state.mining.dashboard.data && state.mining.dashboard.data.range;
export const getDashboardPeriod = (state) => state.mining.dashboard &&
	state.mining.dashboard.period;
export const getDashboardSite = (state) => state.mining.dashboard &&
	state.mining.dashboard.site;

export const getMineceptObstacles$ = (): Subject<IObstacle[]> => {
	return selector$('mining.obstacles');
};

export const getReverseData$ = (): any => {
	return selector$('mining.reverseData');
}
export const getVideoSoundOffset = (state) => state.mining.videoSoundOffset;

export const getVideoSoundAudioOn = (state) => state.mining.videoSoundAudioOn;