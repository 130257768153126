import { makeStyles } from '@material-ui/core/styles';

interface Props{
    size: number
}


export default makeStyles({
    MiniMap: (props: Props) => {
       const style={
           width: props.size + 'px',
           height: props.size + 'px',

       } ;
       return style;
    },
});
