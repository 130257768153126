import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useStyles from './videoPlayer.css';
import CloseIcon from '@material-ui/icons/Close';
import FullScreen from '@material-ui/icons/Fullscreen';
import FullScreenExit from '@material-ui/icons/FullscreenExit';
import {
    setEventPlayed,
    setObstacleAlerts,
    setVideoSoundAudioOn,
    setVideoSoundOffset,
    setWarningSectors
} from '../../store/actions/mining.actions';
import {getObstacleAlerts, getPlayedEvent,getWarningSectors} from '../../store/selectors/mining/mining.selectors';
import {config} from '../../services/config';
import {showMiniMap} from "../../services/obstacles";
import {
    createPlayableTimeline,
    getObstacleByTime,
    getSectors,
    getSectorsByTime,
    ITimelinePlaybackObstacle,
    ITimelinePlaybackSectors
} from '../../services/video';
import videoContext from '../../store/context/videoContext';
import {IObstacleMiniMap} from '../mining-truck/miniMap/MinMap';
import {IMineceptVideoEvent, ITimelineObstacle} from '../../store/reducers/mining/IMiningReducerState';
import {getAudioState} from "../../store/selectors/map.selectors";
import useAccurateTimer from '../../hooks/useAccurateTimer';

interface IVideoSize {
    width: number;
    height: number;
}

const miniMapSize = parseInt(process.env.REACT_APP_MINI_MAP_SIZE as string);

const isEqual = (obj1:any, obj2:any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

function VideoPlayer() {
    const {isMinimized, isAutoPlay, isLoop, isShowControls} = config.general.video;
    const timeDeltaForWheelUpdate = config.general.video.timeDeltaForWheelUpdate;
    const [isClosed, setIsClosed] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(!isMinimized);
    const [videoSize, setVideoSize] = useState<IVideoSize | undefined>(undefined);
    const dispatch = useDispatch();
    const playedEvent: IMineceptVideoEvent = useSelector(getPlayedEvent);
    const classes = useStyles({videoSize, miniMapSize, isFullScreen});
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const sectorsRef = useRef<ITimelinePlaybackSectors[]>([]);
    const obstaclesRef = useRef<ITimelinePlaybackObstacle[]>([]);
    const videoCtx = useContext(videoContext);
    const [wheelSectors, setWheelSectors] = useState<Array<number>>(getSectors());
    const [wheelObstacle, setWheelObstacle] = useState<Array<IObstacleMiniMap> | undefined>(undefined);
    const lastProcessVideoTime = useRef<number>(0);
    const isAudioOn = useSelector(getAudioState);
    const {startTimer, stopTimer} = useAccurateTimer(timeDeltaForWheelUpdate, (latency) => updateWheel(latency));
    const storedObstacles = useSelector(getObstacleAlerts);
    const storedSectors = useSelector(getWarningSectors);

    useEffect(() => {
        const fullscreenHandler = () => {
            if (!document.fullscreenElement && isFullScreen) {
                document.exitFullscreen().catch(() => {
                });
                setIsFullScreen(false);
            }
        };
        document.addEventListener('fullscreenchange', fullscreenHandler);
        return () => document.removeEventListener('fullscreenchange', fullscreenHandler)
    })

    useEffect(() => {
        if (!playedEvent) {
            setIsClosed(true);
            return () => {
                resetStore();
                sectorsRef.current = [];
                obstaclesRef.current = [];
            }
        }
        setIsClosed(false);
        if (videoRef.current) {
            videoRef.current.load();
        }
        // Restore "normal" view
        if (isFullScreen) {
            toggleFullScreen();
        }
        dispatch(setVideoSoundAudioOn(true));
        const sectorsAndObstacles = playedEvent && createPlayableTimeline(playedEvent);
        sectorsRef.current = sectorsAndObstacles.sectors;
        obstaclesRef.current = sectorsAndObstacles.obstacles;
        const updatedSectors = getSectors();
        setWheelSectors(updatedSectors);
        if (!isEqual(storedSectors,updatedSectors)) {
            dispatch(setWarningSectors(updatedSectors));
        }
        setWheelObstacle(undefined);
        if (!isEqual(storedObstacles,[])) {
           dispatch(setObstacleAlerts([]));
        }
    }, [playedEvent]);

    const currentTimeChangeHandler = (value): void => {
        videoCtx.currentTimeChange(value);
    };
    const lastPlayedEventTimeChangeHandler = (value): void => {
        videoCtx.lastPlayedEventTimeChange(value);
    };
    const isPlayedChangeHandler = (isPlaying: boolean): void => {
        videoCtx.isPlayedChange(isPlaying);
        if (isPlaying) {
            startTimer();
        } else {
            stopTimer();
        }
    };
    const clearContextHandler = (): void => {
        videoCtx.clearContext();
    };
    const isObstacleEqual = (prevObstacle: Array<IObstacleMiniMap> | undefined,
                             currObstacle: ITimelineObstacle | undefined): Boolean => {
        if (prevObstacle && currObstacle) {
            return (prevObstacle[0].severity === currObstacle.severity) &&
                (prevObstacle[0].obstacleType === currObstacle.Type);
        }
        return !prevObstacle && !currObstacle;
    };

    const updateWheel = (latency: number) => {
        const currentTime = Math.floor(videoRef.current && videoRef.current.currentTime * 1000 || 0);
        const isEnoughTimePassed = (currentTime - lastProcessVideoTime.current) >= timeDeltaForWheelUpdate;
        const isGoingBack = currentTime < lastProcessVideoTime.current;
        if (!isEnoughTimePassed && !isGoingBack) {
            return;
        }
        lastProcessVideoTime.current = currentTime;

        currentTimeChangeHandler(currentTime);

        let obstacleTime = currentTime;
        let obstacleData = getObstacleByTime(obstacleTime, obstaclesRef.current);
        let frames = Math.ceil(latency / timeDeltaForWheelUpdate);

        for (let i = 0; i < frames; i++) {
            if (obstacleData && obstacleData.value) {
                break;
            }
            obstacleTime = Math.max(0, currentTime - ((i + 1) * timeDeltaForWheelUpdate));
            obstacleData = getObstacleByTime(obstacleTime, obstaclesRef.current);
        }


        if (!obstacleData || !obstacleData.value) {
            obstacleTime = currentTime;
        }

        const updatedSectors = getSectorsByTime(obstacleTime, sectorsRef.current);
        if (updatedSectors !== undefined) {
            setWheelSectors(updatedSectors);
            if (!isEqual(storedSectors, updatedSectors)) {
               dispatch(setWarningSectors(updatedSectors));
            }
        }
        const obstacle = obstacleData && obstacleData.value;
        if (!obstacleData && (obstacleTime < videoCtx.currentTime)) {
            lastPlayedEventTimeChangeHandler(obstacleTime);
            dispatch(setVideoSoundOffset(Math.abs(currentTime - obstacleTime)));
            if (isAudioOn) {
                isPlayedChangeHandler(true);
                dispatch(setVideoSoundAudioOn(true));
            }
            setWheelObstacle(undefined);
            if (!isEqual(storedObstacles, [])) {
                dispatch(setObstacleAlerts([]));
            }
            currentTimeChangeHandler(obstacleTime);
        } else if (obstacleData && !isObstacleEqual(wheelObstacle, obstacle)) {
            const currentObstacle = obstacle ? [{
                severity: obstacle.severity,
                obstacleType: obstacle.Type,
                obstacleDescription: ''
            }] : undefined;
            lastPlayedEventTimeChangeHandler(obstacleData.timestamp);
            dispatch(setVideoSoundOffset(Math.abs(currentTime - obstacleData.timestamp)));
            setWheelObstacle(currentObstacle);
            if (!isEqual(storedObstacles , currentObstacle)) {
                dispatch(setObstacleAlerts(currentObstacle));
            }
            if ((videoRef.current && videoRef.current.paused) || !isAudioOn) {
                isPlayedChangeHandler(false);
                dispatch(setVideoSoundAudioOn(false));
            }
        }
    }

    const timelineUpdate = (event) => {
        event.preventDefault();
        updateWheel(0);
    };

    const pause = (event) => {
        event.preventDefault();
        if(!isEqual(storedObstacles, wheelObstacle)) {
            dispatch(setObstacleAlerts(wheelObstacle));
        }
        isPlayedChangeHandler(false);
        dispatch(setVideoSoundAudioOn(false));
    };

    const play = (event) => {
        event.preventDefault();
        stopTimer();
        if (isAudioOn) {
            isPlayedChangeHandler(true);
            dispatch(setVideoSoundAudioOn(true));
        }
    };

    const resetStore = () => {
        stopTimer();
        clearContextHandler();
        const updatedSectors = getSectors();
        if (!isEqual(storedSectors, updatedSectors)) {
            dispatch(setWarningSectors(updatedSectors))
        }
        if (!isEqual(storedObstacles, [])) {
            dispatch(setObstacleAlerts([]));
            dispatch(setVideoSoundOffset(undefined));
        }
        dispatch(setVideoSoundAudioOn(undefined));
        dispatch(setVideoSoundOffset(undefined));
    }

    const reset = (event) => {
        event.preventDefault();
        resetStore()
        setWheelSectors(getSectors());
        setWheelObstacle(undefined);
    };

    const handleError = (event) => {
        reset(event);
        closeVideo()
    }
    const closeVideo = () => {
        stopTimer();
        setIsClosed(true);
        setVideoSize(undefined);
        if (isFullScreen) {
            document.exitFullscreen().catch(() => {
            });
        }
        clearContextHandler();
        isPlayedChangeHandler(false);
        dispatch(setEventPlayed(undefined));
        dispatch(setObstacleAlerts([]));
        const updatedSectors = getSectors();
        if (!isEqual(storedSectors, updatedSectors)) {
            dispatch(setWarningSectors(updatedSectors))
        }
        dispatch(setVideoSoundOffset(undefined));
        dispatch(setVideoSoundAudioOn(undefined));
        dispatch(setEventPlayed(undefined));
    };
    const toggleFullScreen = () => {
        if (isFullScreen) {
            document.exitFullscreen().catch(() => {
            });
        } else {
            document.documentElement.requestFullscreen().catch(() => {
            });
        }
        setIsFullScreen((prev) => !prev);
    };

    const handleVideoFirstFrame = (e: any) => {
        const video = e.target;
        video.disablePictureInPicture = true;
        setVideoSize({
            width: video.videoWidth,
            height: video.videoHeight
        });
    }

    const calcMiniMapSize = () => {
        let size = miniMapSize;
        if (isFullScreen) {
            size *= 2;
        }
        return size;
    }

    const closedView = () => <></>;
    const containerClasses = () => {
        let className = classes.container;
        if (isClosed) {
            return className;
        }
        if (isFullScreen) {
            className += ` ${classes.containerFull}`;
        } else {
            className += ` ${classes.containerMinimized}`;
        }
        return className;
    }

    const getOpenViewClasses = (isShow: boolean) => {
        let className = classes.playerContainer;
        if (!isShow) {
            className += ` ${classes.hidePlayer}`;
        }
        return className;
    }

    const openedView = (isShow: boolean) => (
        <div className={getOpenViewClasses(isShow)}>
            <div className={classes.toolbar}>
                <div className={classes.button} onClick={closeVideo}>
                    <CloseIcon className={classes.icon}/>
                </div>
                <div className={classes.button} onClick={toggleFullScreen}>
                    {isFullScreen && <FullScreenExit className={classes.icon}/>}
                    {!isFullScreen && <FullScreen className={classes.icon}/>}
                </div>
            </div>
            {playedEvent && <video ref={videoRef} className={classes.player} controls={isShowControls}
                                   autoPlay={isAutoPlay} loop={isLoop} onLoadedMetadata={handleVideoFirstFrame}
                                   onPause={pause} onPlay={play} onResetCapture={reset} onError={handleError}
                                   onTimeUpdate={timelineUpdate} controlsList='nodownload noplaybackrate nofullscreen'>
                <source src={playedEvent.videoUrl} type="video/mp4"/>
            </video>}
            {showMiniMap(wheelSectors, wheelObstacle, calcMiniMapSize(), classes.miniMap, isShow)}
        </div>
    );

    return (
        <div className={containerClasses()}>
            {isClosed && closedView()}
            {openedView(!isClosed)}
        </div>
    )
}

export default VideoPlayer;
