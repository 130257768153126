import { Style, Text, Icon, Fill, Stroke} from 'ol/style';
import { config } from '../../../../services/config';

const defaultVehicle = 'HAUL';
const textStyle = (feature, resolution) => new Text({
		textAlign: 'center',
		font: '400 20px/1 Mukta Mahee',
		text: `${feature.get('vehicleId')}`,
		fill: new Fill({color: 'white'}),
		stroke: new Stroke({color: 'black', width: 3}),
		offsetY: -40,
		// backgroundFill: new Fill({color: 'green'}),
});

const calcImageIndex = (active, selected) =>{
	if(active)
	{
		return selected ? 'activeAndSelected' : 'activeAndUnselected' ;
	}
	return selected ? 'inactiveAndSelected' : 'inactiveAndUnselected' ;
};
const styleFunction = (selected) => (feature, resolution) => {
	const type = feature.getProperties().vehicleType && feature.getProperties().vehicleType.toUpperCase().trim();
	const vehicleType = type in config.vehiclesIcons ? type : defaultVehicle;
	let srcIndex = calcImageIndex(!feature.values_.inActive, selected);

	return new Style({
		image: new Icon({
			anchor: [0.5, 0.5],
			src: config.vehiclesIcons[vehicleType][srcIndex],
			// size: [30,30]
			scale: 0.7,
			rotation:feature.getProperties().rotation,
			rotateWithView: true
		}),
		text: textStyle(feature, resolution)
	});
};


export default {
	selected: styleFunction(true),
	unSelected: styleFunction(false),
};
