import React from 'react';
import { Bar } from 'react-chartjs-2';
import { config } from '../../../../services/config';
import { IMineceptDashboardSiteEventsByHour } from '../../../../store/reducers/mining/IMiningReducerState';

interface IProps {
	graph: IMineceptDashboardSiteEventsByHour;
}

const Chart = ({ graph }: IProps) => {
	const isShowAllHours = config.general.dashboard.isShowAllHours;
	const getHours = (graphHours: number[]) =>
		isShowAllHours ? [...new Array(24).keys()] : graphHours.sort();
	const getHourLabels = (graphHours: number[]) => {
		const hours = getHours(graphHours);
		return hours.map(hour => hour.toString().padStart(2, '0'));
	};
	const getHourValues = (
		graphHours: number[],
		graphData: { [key: string]: number }
	) => {
		const hours = getHours(graphHours);
		return hours.map(hour => graphData[hour] || 0);
	};

	return (
		<Bar
			options={{
				plugins: {
					title: {
						display: true,
						text: 'Operator alerts per time of day',
					},
					datalabels: {
						display: config.general.dashboard.showDataLabels.siteEventsByHour,
						color: 'black',
					},
					legend: {
						display: false,
					},
				},
				maintainAspectRatio: false,
				scales: {
					y: { beginAtZero: true },
				},
			}}
			data={{
				labels: getHourLabels(graph.hours),
				datasets: [
					{
						data: getHourValues(graph.hours, graph.data),
						backgroundColor: config.general.dashboard.hourBarColor,
						borderColor: config.general.dashboard.hourBarColor,
						borderWidth: 1,
					},
				],
			}}
		/>
	);
};

export default Chart;
